import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  HStack,
  VStack, chakra ,
} from "@chakra-ui/react";
import { motion , isValidMotionProp, AnimatePresence} from "framer-motion";
import moment from "moment";
import React, { useState } from "react";
import ImgMaskRevealStatic from "../DefinitionComponents/Animation-Components/ImgMaskRevealStatic";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});
function BlogItem2({ datum, textinhalt, textintro, texttitel, headerimage }) {

  const date = moment(datum).format("DD.MM.YYYY");
  console.log(date);
  const [isToggled, setToggle] = useState(false);
  return (
    <InViewTrigger threshold={0.4} triggerOnce={true}>
      {(inView) => (
        <Container variant="layoutContainer">
          <Box w={{ base: "100%", lg: "100%" }} mt={1}>
            <Flex wrap="wrap" flexDirection={"row"}>
        <Box    w={{ base: "100%", lg: "50%" }}>
                <ImgMaskRevealStatic
                  imgsrc={headerimage}
                  initScale={1.5}
                  delay={0}  
                  duration={2}
                  threshold={0.4}
                  triggerOnce={true}
                  maxH="390px"
                />
           </Box>
              <VStack
                w={{ base: "100%", lg: "50%" }}
                px={10}
                py={10}
                alignItems={"flex-start"}
                bg={"brand.lightgray"}
              >
                <Heading
                  fontSize="md"
                  fontWeight="normal"
                  lineHeight={"none"}
                  color="brand.blue"
                >
                  {date}
                </Heading>
                <Heading
                  w={{ base: "100%", lg: "90%" }}
                  fontSize="lg"
                  fontWeight="normal"
                  lineHeight={"shorter"}
                  color="brand.meddarkgray"
                >
                  {texttitel}
                </Heading>
                <Heading
                  w={{ base: "100%", lg: "90%" }}
                  fontSize="md"
                  fontWeight="normal"
                  lineHeight={"shorter"}
                  color="brand.meddarkgray"
                  dangerouslySetInnerHTML={{
                    __html: textintro,
                  }}
                ></Heading>

                {!isToggled && (
                  <Button
                    onClick={() =>
                      setToggle((prevValue) => (prevValue ? false : true))
                    }
                    color="white"
                    size={"md"}
                    fontWeight={"normal"}
                    px={6}
                    backgroundColor="brand.medgray"
                  >
                    <Text color="brand.white">Read more</Text>
                  </Button>
                )}
                  <AnimatePresence>
                {isToggled && (
                  
                  <ChakraBox 
                  
                  animate={{
                    height:"100%",
                    scale: [0.95, 1],
                    opacity: [0.2,1],
                    y: [20,0]
                   
                 
                  }}
                  exit={{ 
                    height:"1px",
                    opacity: 0 ,
                    scale: [1, 0.95],
                    y: 50
                  }}
                  // @ts-ignore no problem in operation, although type error appears.
                  transition={{
                    duration: 1.5,
                    ease: [0.04, 0.62, 0.23, 0.98],
                    repeatType: "once",
                  }}
                  >
                    <Heading
                      w={{ base: "100%", lg: "90%" }}
                      fontSize="md"
                      fontWeight="normal"
                      lineHeight={"shorter"}
                      color="brand.meddarkgray"
                      dangerouslySetInnerHTML={{
                        __html: textinhalt,
                      }}
                    ></Heading>
                  </ChakraBox>
                 
                )}
 </AnimatePresence>
                {isToggled && (
                  <Box w="100%">
                    <Button
                      onClick={() =>
                        setToggle((prevValue) => (prevValue ? false : true))
                      }
                      color="white"
                      size={"lg"}
                      fontWeight={"normal"}
                      px={6} display="flex"
                      backgroundColor="brand.medgray"
                    >
                      <svg
                        display="flex"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        onClick={() =>
                          setToggle((prevValue) => (prevValue ? false : true))
                        }
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.5303 14.0303C16.2374 14.3232 15.7626 14.3232 15.4697 14.0303L12 10.5607L8.53033 14.0303C8.23744 14.3232 7.76256 14.3232 7.46967 14.0303C7.17678 13.7374 7.17678 13.2626 7.46967 12.9697L11.4697 8.96967C11.7626 8.67678 12.2374 8.67678 12.5303 8.96967L16.5303 12.9697C16.8232 13.2626 16.8232 13.7374 16.5303 14.0303Z"
                          fill="brand.white"
                        />
                      </svg>
                      <Text display="flex" color="brand.white">
                        Close
                      </Text>
                    </Button>
                  </Box>
                )}
              </VStack>
            </Flex>
          </Box>
        </Container>
      )}
    </InViewTrigger>
  );
}

export default BlogItem2;
