import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Text,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import ImgTextModule from '../components/ImgTextModule';
import QuadKachelnInvRelations from '../components/QuadKachelnInvRelations';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import BlogItem from '../components/BlogItem';
import BlogItem2 from '../components/BlogItem2';

const Blog = () => {
    const { setColorMode } = useColorMode();

    useEffect(() => {
        setColorMode('light');
    });

    const data = useStaticQuery(graphql`
        query getBlog {
            allWpBlogelement(sort: { fields: date, order: DESC }) {
                nodes {
                    dateGmt
                    date(formatString: "DD.MM.yyyy", locale: "DE")
                    blogDate
                    blogTitle
                    introText
                    articleContent
                    headerimage {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    `);
    const DIDDS = data.allWpBlogelement.nodes;

    return (
        <>
            <Helmet>
                <title>tokentus | Blog</title>
                <meta
                    name='description'
                    content='Blog News provided by tokentus investment AG.'
                />
                <link
                    rel='canonical'
                    href='https:/tokentus.com/blog'
                />
            </Helmet>

            <ImgTextModule
                src={
                    '47888_Businessman_walking_up_stairs_holding_newspaper_By_Sergiu_Jurca_Artlist_HD.mp4'
                }
                delay={0.3}
                duration={0.5}
                threshold={0.3}
                initialY={10}
                initialX={10}
                video={true}
                h='95vh'
                mediaOpacity={0.8}
                bgColor='brand.black'>
                <Container
                    variant='layoutContainer'
                    mt={8}>
                    <Box p={8}>
                        <FadeInAnimation
                            threshold={0.4}
                            delay={0.4}
                            duration={0.7}
                            initialX={0}
                            initialY={-10}>
                            <Text
                                color='white'
                                fontWeight='normal'
                                fontSize='xl'>
                                Regular blog
                            </Text>
                        </FadeInAnimation>
                        <FadeInAnimation
                            threshold={0.4}
                            delay={0.4}
                            duration={0.7}
                            initialX={0}
                            initialY={-10}>
                            <Text
                                color='brand.green'
                                fontWeight='normal'
                                fontSize={{ base: '5xl', lg: '6xl' }}>
                                tokentus informs.
                            </Text>
                        </FadeInAnimation>

                        <FadeInAnimation
                            threshold={0.4}
                            delay={1}
                            duration={0.7}
                            initialX={0}
                            initialY={10}>
                            <Text
                                color='brand.white'
                                mb={24}
                                maxW={{ base: '100%', lg: '50%' }}
                                fontSize='2xl'>
                                News from our portfolio companies and the
                                blockchain industry.
                            </Text>
                        </FadeInAnimation>
                    </Box>
                </Container>
            </ImgTextModule>
            {/* NEWS */}

            <Container
                variant='layoutContainer'
                px={{ base: 0, lg: 10 }}
                py={{ base: 0, lg: 5 }}>
                {DIDDS.map((News) => (
                    <>
                        <BlogItem2
                            datum={News.blogDate}
                            textinhalt={News.articleContent}
                            textintro={News.introText}
                            texttitel={News.blogTitle}
                            headerimage={News.headerimage.localFile.publicURL}
                        />
                    </>
                ))}{' '}
            </Container>
            <QuadKachelnInvRelations />
        </>
    );
};

export default Blog;
